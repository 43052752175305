import { createGlobalStyle } from 'styled-components';

import theme from './theme';

export default createGlobalStyle`
  body {
    font-family: ${theme.font};
    font-size: ${theme.fontSize};
    min-width: 100%;
    min-height: 100%;
  }
  input {
    font-family: ${theme.font};
  }
  fieldset {
    border: none;
    padding: 0;
    width: 100%;
  }
  legend {
    display: block;
  }
  [data-reach-menu-popover] {
    z-index: 99;
  }
`;
