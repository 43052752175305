import React from 'react'
import { Provider } from 'use-http';

import 'isomorphic-fetch'
import 'url-search-params-polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

const HOST = process.env.NODE_ENV === 'development' ? (process.env.USE_DEV_API * 1 ? process.env.DEV_API_HOST : `https://${process.env.APP_HOST}`) : ''

export default ({ children, options }) => (
  <Provider url={HOST + process.env.API_PATH} options={options}>
    {children}
  </Provider>
)
