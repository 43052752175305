import React, { forwardRef } from 'react'
import { flexbox } from 'styled-system';
import styled from 'styled-components';

import Box from './Box';
import asForward from './utils/asForward'
import { responsive } from './ThemeProvider/theme'

const Flex = asForward(styled(Box)`
  ${flexbox}
`);

Flex.defaultProps = {
  display: 'flex',
};

Flex.displayName = 'Flex';

Flex.Responsive = forwardRef((props, ref) => (
  <Flex flexDirection={responsive('column', 'row')} ref={ref} {...props} />
))

export default Flex;
