import React from 'react'

import Box from 'components/Box'
import Text from 'components/Text'
import { responsive } from 'components/ThemeProvider/theme';

const Footer = () => {
  return (
    <Box
      as="footer"
      color="white"
      bg="darkPurple"
      px={responsive('2.25em', '7.625em')}
      pt={responsive('3.875em', '2.5em')}
      pb={responsive('4.25em', '3em')}
    >
      <Text fontSize="1.125em" mb="1em">如對於聲請調解案件有任何疑問者，歡迎您跟新北市各區調解委員會聯繫詢問</Text>
      <Text>服務時間為星期一至星期五：上午8:00~12:30～下午13:30~18:00(週六、日及國定假日休息)</Text>
      <Text my={responsive('1em', 0)}>本網站為新北市政府法制局版權所有，請尊重智慧財產權，未經允許請勿任意轉載、複製或做商業用途</Text>
      <Text>建議使用IE11或Firefox瀏覽器或Google Chrome瀏覽器，建議瀏覽解析度為1920 x 1080</Text>
    </Box>
  )
}

export default Footer
