import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Box from 'components/Box';
import theme from 'components/ThemeProvider/theme';

import Header from '../Header'
import Footer from '../Footer'

const Layout = ({ children, noHeader, bg, ...props }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={({ site: { siteMetadata: { title, description } } }) => {
      return (
        <>
          <Helmet>
            <html lang="zh-TW" />
            <title>{title}</title>
            <meta name="description" content={description} />
          </Helmet>
          {!noHeader && <Header height={theme.headerHeight} siteTitle={title} />}
          <Box as="main" pt={!noHeader && theme.headerHeight} bg="lightGray" minHeight="100vh" {...props}>
            {children}
          </Box>
          <Footer />
        </>
      );
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
