import merge from 'lodash/merge';
import range from 'lodash/range';
import get from 'lodash/get';

import memorize from '../../utils/memorize'

const baseFontSize = 16
const emToPx = (em) => `${em * baseFontSize}px`;

export const breakpoints = [22, 36, 48, 62, 90, 120].map(emToPx);
export const containerWidth = [22, 36, 46, 58, 75].map(emToPx);
export const responsiveIndex = [
  [3, 'mobile'],
  [4, 'tablet'],
]

const responsiveMap = breakpoints.map((_, i) => {
  const id = responsiveIndex.findIndex(([ri]) => ri > i)
  return id >= 0 ? id : responsiveIndex.length
})

export const responsive = memorize((...args) => {
  const argsLen = args.length
  if (argsLen <= 1) return args[0]
  return breakpoints.map((_, i) => get(args, [responsiveMap[i]], null))
});

export const mobileOrDesktop = responsive

const generateFade = (r, g, b) => range(10, 100, 10)
  .reduce((fade, opacity) => merge(fade, { [opacity]: `rgba(${[r, g, b, opacity / 100].join()})` }), {});

const font = 'Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';

// #FF8A00
const white = '#fff';
const black = '#000';
const blue = '#4B63C3';
// const lightPurple = '#D6DEFF';
const lightPurple = '#EBEFFF';
const darkPurple = '#403E88';
const purple ="#6980DB";
const purpleHover ="#44569e";
const orange = '#E27900';
const lightBlack ="#383F43";
const lightGray = "#f4f4f4"
const shadowGray = "#C8C8C8"
const red = '#ff6b6b';
const green = '#51cf66'

export default {
  colors: {
    white,
    black,
    blue,
    darkPurple,
    lightPurple,
    lightGray,
    shadowGray,
    purple,
    purpleHover,
    orange,
    lightBlack,
    red,
    green,
    // btnText,
    text: lightBlack,
    primary: purple,
    danger: red,
    dangerHover: '#c92a2a',
    fade: {
      white: generateFade(255, 255, 255),
      black: generateFade(0, 0, 0),
    },
  },
  breakpoints,
  containerWidth,
  font,
  fontSize: `${baseFontSize}px`,
  headerHeight: responsive('3.75em', '5em'),
  responsive,
  zOrder: range(4).map((i) => 10 ** i),
};
