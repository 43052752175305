import React, { useCallback, useState } from 'react';

import Context from './context'

const Provider = ({ children }) => {
  const [res, setRes] = useState()
  const handleRes = useCallback((data) => {
    setRes(data)
  }, [setRes])

  return (
    <Context.Provider value={{ res, handleRes }}>
      {children}
    </Context.Provider>
  )
}

export default Provider;
