import React from 'react'

import Box from 'components/Box'

export default ({ children, ...props }) => (
  <Box
    color="darkPurple"
    pt="0.75em"
    pl="1.5em"
    pb="0.75em"
    ml="0.75em"
    mr="2.25em"
    display="block"
    fontWeight="bold"
    borderBottom="1px solid"
    {...props}
  >
    {children}
  </Box>
)
