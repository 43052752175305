import React from 'react'

import ThemeProvider from './src/components/ThemeProvider'
import MediaQueryProvider from './src/contexts/mediaQuery/MediaProvider'
import DataProvider from './src/contexts/data/DataProvider'
import Layout from './src/containers/Layout'
import ProgressProvider from './src/contexts/progress/Provider'

export default ({ element }) =>
  <ThemeProvider>
    <MediaQueryProvider>
      <DataProvider>
        <Layout>
          <ProgressProvider>
            {element}
          </ProgressProvider>
        </Layout>
      </DataProvider>
    </MediaQueryProvider>
  </ThemeProvider>
